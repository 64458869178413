import { JWT_TOKEN, LOGIN, PRODUCT, TEMP_DATA } from "../types";

const INITIAL_STATE = {
    tempData: null,
    rockJwtToken: null,
    products:[],
    loginDeatils:null,
    clientPI:null,
    cart:null,
    
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case TEMP_DATA:
            return {
                ...state,
                tempData: payload,
            };
        case JWT_TOKEN:
            return {
                ...state,
                rockJwtToken: payload,
            };
        case PRODUCT:
            return {
                ...state,
                products: payload,
            };
        case LOGIN:
            return {
                ...state,
                loginDeatils: payload,
            };
        case 'CLIENT_PI':
            return {
                ...state,
                clientPI: payload,
            };
        case 'CART':
            return {
                ...state,
                 cart: payload,
            };
        default:
            return state;
    }
};
